<template>
  <div class="index">
    <Header :seet="number" orderBy="id" />
    <el-row type="flex" class="index_cent">
      <el-col :span="3" style="width:15.3%;"></el-col>
      <el-col :span="17">
        <div class="index-top">
          <div class="index-classify" @mouseleave="leave()">
            <ul>
              <li
                v-for="(item, index) in classify"
                :key="index"
                @mouseenter="enter(item.id)"
                @click="brandSearch(item.id, 1)"
                :class="brandId == item.id ? 'list-selected' : 'list-select'"
              >
                {{ item.name }}
              </li>
            </ul>
            <div class="classify-box" v-show="showClassify">
              <div
                class="classify-hang"
                v-for="(item, index) in classBox"
                :key="index"
              >
                <div
                  style="display:flex;"
                  class="classify-item"
                  @click="brandSearch(item.id, 2)"
                >
                  {{ item.name }} <span>></span>
                </div>
                <div class="hang-box">
                  <div
                    v-for="(items, index) in item.goodsTypeVOList"
                    :key="index"
                    class="classify-item"
                    @click="brandSearch(items.id, 3, item.id)"
                  >
                    {{ items.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="index-carousel">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item v-for="(item, index) in carousel" :key="index">
                <img :src="item.fileUrl" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="goods_box">
          <div
            class="goods_box_cent"
            v-for="(item, index) in goodsBox"
            :key="index"
          >
            <div class="goods_box_item" @click="goodsDetail(item.id)">
              <div class="img-wrapper flex-center">
                <img :src="item.homePicture" alt="" class="img" />
              </div>
              <!--  -->
              <el-tooltip :disabled="calcLength(item)" :content="item.externalId ? item.externalGoodsName : item.goodName" effect="dark" placement="top">
                <div class="item_title more-ell">
                    <span class="title_name" v-if="item.sourceType == 0"
                    >自营</span
                    >
                    <span class="title_name" v-if="item.sourceType == 1"
                    >京东</span
                    >
                    <span class="title_name" v-if="item.sourceType == 2"
                    >震坤行</span
                    >
                    <span class="title_name" v-if="item.sourceType == 3"
                    >怡亚通</span
                    >
                    {{ item.externalId ? item.externalGoodsName : item.goodName }}
                </div>
              </el-tooltip>
              <div class="item_price">￥{{ item.externalId ? item.externalMinPrice : item.minPrice }}</div>
            </div>
          </div>
        </div>
        <div class="secondaryConfirma">
          <el-dialog
                  title="财务部通知"
                  :visible.sync="secondaryConfirmationDialogVisible2"
                  :destroy-on-close="true"
                  width="500px"
                  top="10vh"
                  center
          >
            <div v-loading="secondaryLoading" class="secondary-content">
              <div class="secondary-text">
                <span>自2025年1月1日起，所有在禹佳商城采购商品必须在付款完成后，方可申请开具发票。具体通知点击进入下方链接！</span>
              </div>
              <div class="secondary-text">
                <span>下载附件：</span>
                <a href="https://zh-mall.obs.cn-north-4.myhuaweicloud.com/yjweb/webFile/%E8%B4%A2%E5%8A%A1%E9%83%A8%E9%80%9A%E7%9F%A51%281%29.docx" style="color: #02A7F0;font-weight: bold;">下载文件(可点击下载)</a>
              </div>
              <div class="secondary-button">
                <el-button
                        type="primary"
                        size="small"
                        @click="secondaryConfirmation2()"
                        :disabled="countdown > 0" class="close-button"
                >确定(倒计时: {{ countdown }})</el-button>

              </div>
            </div>
          </el-dialog>
        </div>
        <!-- <div class="index_infor">
          <div class="infor_box flex-start">
              <img src="@/assets/image/tu1.png" alt="">
              <div class="infor_title">
                <div class="infor_title_name">正品保障</div>
                <div>100%品牌官方正品</div>
              </div>
          </div>  
          <div class="infor_box flex-start">
              <img src="@/assets/image/tu1.png" alt="">
              <div class="infor_title">
                <div class="infor_title_name">售后无忧</div>
                <div>7天无理由退换货</div>
              </div>
          </div>  
          <div class="infor_box flex-start">
              <img src="@/assets/image/tu1.png" alt="">
              <div class="infor_title">
                <div class="infor_title_name">私人订制</div>
                <div>私人专属订制</div>
              </div>
          </div>  
          <div class="infor_box flex-start">
              <img src="@/assets/image/tu1.png" alt="">
              <div class="infor_title">
                <div class="infor_title_name">急速配送</div>
                <div>多仓库直发</div>
              </div>
          </div>  
        </div> -->
      </el-col>
      <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import { Classify, Inquire } from "@/api/api";
import { lookApproveFile } from "@/api/order/order";
export default {
  components: { Header, Footer },
  data() {
    return {
      number: "1",
      classify: [],
      classBox: [],
      showClassify: false,
      brandId: '',
      carousel: [
        { fileUrl: require("@/assets/image/pack_1.jpg") },
        { fileUrl: require("@/assets/image/pack_2.jpg") }
      ],
      goodsBox: [],
      pageIndex: 1,
      pageSize: 20,
      total: 0,
      orderBy: "id",
      scrollTop: "",
      goodsName: "",
      name: "",
      samllname: "",
      throttle: false,
      countdown: 0, // 倒计时秒数
      timer: null, // 计时器
      secondaryConfirmationDialogVisible2: false,
    };
  },
  created() {
    Classify().then(data => {
      if (data && data.code === "00000") {
        this.classify = data.data;
      }
    });
    this.getCarousel();
    this.getList();
    // userInfor().then(data => {
    //   let identity = data.data.roleList;
    //   localStorage.setItem("identity", JSON.stringify(identity));
    // });
    
  },
  computed: {
    GoodsList: {
      get() {
        return this.$store.state.common.GoodsList;
      },
      set(val) {
        this.$store.commit("common/updategoodsList", val);
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isShowTip();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    calcLength(item) {
        let width = document.querySelector('.goods_box').clientWidth * 19 / 100 - 44
        let textLength = item.externalId ? item.externalGoodsName.length : item.goodName.length
        let length = parseInt(14 * (textLength + 3))
        // console.log(length,width);
        if(length >= (width * 2)){
            return false
        }
        return true
    },
    secondaryConfirmation2(){
      this.secondaryConfirmationDialogVisible2 = false
    },
    isShowTip(){
      // eslint-disable-next-line no-unused-vars
      const newDate = new Date().getTime();
      const tomorrow = localStorage.getItem('caiwuTip3');
      // eslint-disable-next-line no-empty
      console.log("newDate",newDate);
      console.log("tomorrow",tomorrow);
      if (tomorrow==null || newDate > tomorrow){
        const tomorrow2 = new Date().getTime()+(24*60*60*1000);
        console.log("tomorrow2",tomorrow2);
        localStorage.setItem('caiwuTip3', tomorrow2);
        this.secondaryConfirmationDialogVisible2 = true;
        this.startCountdown();
      }


    },
    // 开始倒计时
    startCountdown() {
      this.countdown = 8; // 设置初始倒计时时间
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(this.timer); // 倒计时结束清除计时器
        }
      }, 1000);
    },
    // 关闭弹窗
    closeModal() {
      if (this.countdown === 0) {
        // 关闭弹窗的逻辑
        // ...
        // 清除计时器
        clearInterval(this.timer);
      }
    },
    handleScroll(e) {
      this.scrollTop =
        e.target.documentElement.scrollTop || e.target.body.scrollTop;
      let scrollTop = e.target.documentElement.scrollTop;
      let clientHeight = e.target.documentElement.clientHeight;
      let scrollHeight = e.target.documentElement.scrollHeight;
      // console.log(scrollTop,clientHeight)
      // console.log(scrollHeight)
      // console.log(scrollTop + clientHeight >= scrollHeight,this.Isload)
      if (scrollTop + clientHeight >= scrollHeight-1 && this.throttle && this.goodsBox.length < this.total) {
        this.throttle = false // 节流
        this.getList();
      }
    },
    getCarousel() {
        lookApproveFile({
            id: 1,
            type: 15
        }).then(res => {
            if(res.code == '00000' && res.data.length > 0){
                this.carousel = res.data
            }
        }).catch(e => {
            console.log(e.message);
        })
    },
    getList() {
      let data = {
        brand: [],
        myPage: {
          pageNum: this.pageIndex,
          pageSize: this.pageSize
        },
        orderBy: this.orderBy,
        index: true,
        desc: 1
      };
      Inquire(data).then(data => {
        if (data.code === "00000") {
          this.goodsBox = [...this.goodsBox, ...data.data.goodsList];
          this.total = data.data.total
          this.pageIndex++
        }
        this.throttle = true
      }).catch(()=>{this.throttle = true});
    },
    goodsDetail(id) {
      if(this.$routerWin){// 打开新窗口
        const { href } = this.$router.resolve({ name: "goodsDetail", query: { id: id } });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "goodsDetail", query: { id: id } });
      }
    },
    // 鼠标移入品牌列表
    enter(id) {
      this.showClassify = true
      this.brandId = id
      this.classify.forEach(item => {
        if (item.id === id) {
          this.classBox = item.goodsTypeVOList;
        }
      });
    },
    // 鼠标移出
    leave() {
      this.showClassify = false
      this.brandId = ''
    },
    
    // 品牌分类搜索
    brandSearch(id, level, pid){
      let obj = {}
      if(level === 1){
        obj = {
          id,
          level,
        }
      } else if(level === 2){
        obj = {
          id: this.brandId,
          id2: id,
          level,
        }
      } else if(level === 3){
        obj = {
          id: this.brandId,
          id2: pid,
          id3: id,
          level,
        }
      }
      if(this.$routerWin){
        const { href } = this.$router.resolve({ name: "SearchGoods", query: obj });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "SearchGoods", query: obj });
      }
    },
    
  },
};
</script>
<style lang="scss">
.index_cent {
  background: #f7f7f7;
  .index-top {
    height: 460px;
    display: flex;
    background: #fff;
    padding-bottom: 10px;
    position: relative;
    .index-classify {
      width: 14.5%;
      overflow-y: auto;

      .list-selected {
        cursor: pointer;
        color: #cd9f49;
        background: #f7f7f7;
      }
      .list-select {
        cursor: pointer;
        background: #fff;
        color: black;
      }
      .classify-item {
        color: #000;
        &:hover {
          cursor: pointer;
          color: #cd9f49;
        }
      }
      
      li {
        height: 34px;
        line-height: 34px;
        padding-left: 20px;
      }
      .classify-box {
        position: absolute;
        top: 0;
        left: 14.5%;
        width: 700px;
        height: 95.5%;
        background: #fff;
        z-index: 999;
        border: 1px solid #e7e7e7;
        border-bottom: none;
        padding: 10px 25px;
        .classify-hang {
          display: flex;
          padding: 8px 0 14px;
          border-bottom: 1px solid #e7e7e7;
          .hang-box {
            display: flex;
            flex-flow: wrap;
            width: 86%;
            div {
              padding: 0 10px 5px;
            }
          }
        }
      }
    }
    .index-classify::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.8);
    }
    .index-classify::-webkit-scrollbar {
      width: 3px;
      background-color: rgba(0, 0, 0, 0);
    }
    .index-carousel {
      width: 85.5%;
      height: 100%;
      position: absolute;
      z-index: 0;
      right: 0;
      .el-carousel--horizontal {
        height: 100%;
      }
      .el-carousel__container {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .goods_box {
    margin-top: 10px;

    .goods_box_cent:nth-child(5n) {
      margin-right: 0;
    }
    .goods_box_cent {
      display: inline-block;
      width: 19.39%;
      margin-right: 0.75%;
      margin-bottom: 10px;
      background: #fff;
      cursor: pointer;
      .goods_box_item {
        padding: 22px;
        .img {
          width: 100%;
          height: 170px;
        }
        .item_title {
          position: relative;
          margin-top: 32px;
          margin-bottom: 25px;
          font-size: 14px;
          color: #666;
          line-height: 20px;
          height: 40px;
          overflow: hidden;
          .title_name {
            color: #fff;
            background: #cd9f49;
            padding: 0 5px;
          }
          .hover-item {
            display: none;
            border-radius: 4px;
            background-color: #303133;
            position: absolute;
          }
        }
        .item_price {
          color: #f2270c;
          text-align: left;
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
    .goods_box_cent:hover {
      box-shadow: 0px 0px 5px #cd9f49;
    }
  }
  .index_infor {
    display: flex;
    width: 86%;
    margin: 33px auto;
    .infor_box {
      width: 22%;
      margin-right: 40px;
      .infor_title {
        margin-left: 10px;
        font-size: 14px;
        .infor_title_name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
  }
}
.secondaryConfirma {
  .secondary-button {
    margin: 15px 0;
    text-align: center;
  }
  .secondary-text {
    color: rgb(39, 38, 38);
    margin-bottom: 15px;
  }
  .secondary-title {
    font-weight: 600;
    font-size: 17px;
    margin: 15px 0;
    color: #000;
  }
  .secondary-content {
    padding: 0 10px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgb(226, 226, 226) !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 0 !important;
    margin: 0 20px 20px !important;
  }
}
</style>
